// ../../../react-shim.js
import React from "react";

// src/skeleton.tsx
import { useBreakpointValue } from "@chakra-ui/media-query";
import {
  chakra,
  forwardRef,
  keyframes,
  omitThemingProps,
  useStyleConfig
} from "@chakra-ui/system";
import { usePrevious } from "@chakra-ui/react-use-previous";

// ../../utilities/shared-utils/src/index.ts
var cx = (...classNames) => classNames.filter(Boolean).join(" ");

// src/skeleton.tsx
import { useEffect, useRef } from "react";
var StyledSkeleton = chakra("div", {
  baseStyle: {
    boxShadow: "none",
    backgroundClip: "padding-box",
    cursor: "default",
    color: "transparent",
    pointerEvents: "none",
    userSelect: "none",
    "&::before, &::after, *": {
      visibility: "hidden"
    }
  }
});
var useIsFirstRender = () => {
  const isFirstRender = useRef(true);
  useEffect(() => {
    isFirstRender.current = false;
  }, []);
  return isFirstRender.current;
};
var fade = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 }
});
var startColor = "var(--skeleton-start-color)";
var endColor = "var(--skeleton-end-color)";
var bgFade = keyframes({
  from: { borderColor: startColor, background: startColor },
  to: { borderColor: endColor, background: endColor }
});
var Skeleton = forwardRef((props, ref) => {
  const styles = useStyleConfig("Skeleton", props);
  const isFirstRender = useIsFirstRender();
  const {
    startColor: startColor2,
    endColor: endColor2,
    isLoaded,
    fadeDuration,
    speed,
    className,
    ...rest
  } = omitThemingProps(props);
  const wasPreviouslyLoaded = usePrevious(isLoaded);
  const _className = cx("chakra-skeleton", className);
  if (isLoaded) {
    const animation = isFirstRender || wasPreviouslyLoaded ? "none" : `${fade} ${fadeDuration}s`;
    return /* @__PURE__ */ React.createElement(chakra.div, {
      ref,
      className: _className,
      __css: { animation },
      ...rest
    });
  }
  return /* @__PURE__ */ React.createElement(StyledSkeleton, {
    ref,
    className: _className,
    ...rest,
    __css: {
      ...styles,
      animation: `${speed}s linear infinite alternate ${bgFade}`
    }
  });
});
Skeleton.defaultProps = {
  fadeDuration: 0.4,
  speed: 0.8
};
Skeleton.displayName = "Skeleton";
function range(count) {
  return Array(count).fill(1).map((_, index) => index + 1);
}
var defaultNoOfLines = 3;
var SkeletonText = (props) => {
  const {
    noOfLines = defaultNoOfLines,
    spacing = "0.5rem",
    skeletonHeight = "0.5rem",
    className,
    startColor: startColor2,
    endColor: endColor2,
    isLoaded,
    fadeDuration,
    speed,
    children,
    ...rest
  } = props;
  const noOfLinesValue = useBreakpointValue(typeof noOfLines === "number" ? [noOfLines] : noOfLines) || defaultNoOfLines;
  const numbers = range(noOfLinesValue);
  const getWidth = (index) => {
    if (noOfLinesValue > 1) {
      return index === numbers.length ? "80%" : "100%";
    }
    return "100%";
  };
  const _className = cx("chakra-skeleton__group", className);
  return /* @__PURE__ */ React.createElement(chakra.div, {
    className: _className,
    ...rest
  }, numbers.map((number, index) => {
    if (isLoaded && index > 0) {
      return null;
    }
    const sizeProps = isLoaded ? null : {
      mb: number === numbers.length ? "0" : spacing,
      width: getWidth(number),
      height: skeletonHeight
    };
    return /* @__PURE__ */ React.createElement(Skeleton, {
      key: numbers.length.toString() + number,
      startColor: startColor2,
      endColor: endColor2,
      isLoaded,
      fadeDuration,
      speed,
      ...sizeProps
    }, index === 0 ? children : void 0);
  }));
};
SkeletonText.displayName = "SkeletonText";
var SkeletonCircle = ({
  size = "2rem",
  ...rest
}) => /* @__PURE__ */ React.createElement(Skeleton, {
  borderRadius: "full",
  boxSize: size,
  ...rest
});
SkeletonCircle.displayName = "SkeletonCircle";
export {
  Skeleton,
  SkeletonCircle,
  SkeletonText
};
